import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { NotificationsService } from '../../../core/notifications/notifications.service';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-notifications',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './notifications.component.html',
  styleUrl: './notifications.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationsComponent implements OnInit {
  showNotification = false;
  message: string | null = null;

  constructor(
    private notificationsService: NotificationsService,
    private readonly cd: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.notificationsService
      .getNotificationObservable()
      .subscribe((message) => {
        this.message = message;
        this.showNotification = message !== null;
        this.cd.detectChanges();
      });
  }

  closeNotification() {
    this.notificationsService.closeCurrentNotification();
  }
}
