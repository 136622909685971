<div class="d-flex header">
  <div class="icon-container">
    @if (showGoBackIcon) {
      <a (click)="goBack()">
        <img
          class="go-back-icon"
          src="assets/images/go_back.svg"
          alt="Icon"
          width="30" />
      </a>
    }
  </div>

  @if (playerName | async; as name) {
    <div class="user-id">{{ name }}</div>
  }
  <div class="icon-container">
    <a (click)="logout()">
      <img
        class="logout-icon"
        src="assets/images/Vector.svg"
        alt="Icon"
        width="30" />
    </a>
  </div>
</div>
<div class="safe-zone">
  <router-outlet />
</div>
