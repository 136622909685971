import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { setPlayerId } from '../../core/store/actions/player.actions';
import { AppSettingsService } from '../../services/app.settings.service';
import { NotificationsComponent } from '../../ui/notifications/notifications/notifications.component';
import { TranslateService } from '@ngx-translate/core';
import { SettingsService } from '../../services/settings.service';
import {
  setAppLocale,
  setMaxWithdrawValue,
  setMinWithdrawValue,
} from '../../core/store/actions/settings.actions';

@Component({
  selector: 'app-init-settings',
  standalone: true,
  imports: [NotificationsComponent],
  template: ``,
})
export class InitSettingsComponent implements OnInit {
  constructor(
    private readonly store: Store,
    private readonly appSettingsService: AppSettingsService,
    private readonly settingsService: SettingsService,
    private readonly translate: TranslateService,
  ) {
    const browserLang = this.translate.getBrowserLang();
    const locale = browserLang?.match(/ru|en|es|pt/) ? browserLang : 'ru';
    this.translate.use(locale);
    this.store.dispatch(setAppLocale({ locale }));
  }

  ngOnInit(): void {
    this.storePlayerData();
    this.getPortalSettings();
  }

  private storePlayerData() {
    const playerId = localStorage.getItem('playerId');
    if (playerId) {
      this.store.dispatch(setPlayerId({ playerId: playerId }));
      this.appSettingsService.initiateSettings();
    }
  }

  private getPortalSettings() {
    this.settingsService.getPortalSettings().subscribe({
      next: (data) => {
        this.store.dispatch(
          setMinWithdrawValue({
            minWithdrawValue: data.portalSettings.min_withdraw_value,
          }),
        );
        this.store.dispatch(
          setMaxWithdrawValue({
            maxWithdrawValue: data.portalSettings.max_withdraw_value,
          }),
        );
      },
    });
  }
}
