import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { inject, Injector } from '@angular/core';
import { NotificationsService } from '../../core/notifications/notifications.service';

export const errorInterceptor: HttpInterceptorFn = (req, next) => {
  const injector = inject(Injector);
  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
      if (error.url?.includes('auth/refresh')) {
        return throwError(() => error);
      }
      const notification = injector.get(NotificationsService);
      if (error.status === 400) {
        console.error('HTTP Error:', error);
      }
      if (error.status === 500) {
        notification.openNotification('SERVER_ERROR');
      }
      return throwError(() => error);
    }),
  );
};
