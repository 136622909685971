import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { FAQItem } from '../../dashboard/dashboard/models/faq-item.model';
import { NgClass } from '@angular/common';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { SettingsService } from '../../../services/settings.service';

@Component({
  selector: 'app-faq',
  standalone: true,
  imports: [TranslateModule, NgClass],
  templateUrl: './faq.component.html',
  styleUrl: './faq.component.scss',
  animations: [
    trigger('slideToggle', [
      state(
        'collapsed',
        style({
          width: '55px',
          transform: 'translateX(0)',
        }),
      ),
      state(
        'expanded',
        style({
          width: '100%',
          transform: 'translateX(0)',
        }),
      ),
      transition('collapsed <=> expanded', [animate('0.5s ease')]),
    ]),
    trigger('fadeInOut', [
      state('in', style({ opacity: 1, height: '*' })),
      state('out', style({ opacity: 0, height: '0' })),
      transition('in <=> out', animate('0.5s ease')),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FaqComponent implements OnInit {
  protected showFAQ = false;
  protected faqItems: FAQItem[] = [];

  constructor(private readonly settingsService: SettingsService) {}

  ngOnInit(): void {
    this.getFaqItems();
  }

  private getFaqItems() {
    this.settingsService.getFaqItems().subscribe({
      next: (data) => {
        if (data && data.faq && data.faq.length > 0) {
          this.faqItems = data.faq.map((item) => {
            item.isOpen = false;
            return item;
          });
        }
      },
    });
  }

  toggleFAQ() {
    this.showFAQ = !this.showFAQ;

    if (this.showFAQ) {
      setTimeout(() => {
        const faqButton = document.querySelector('.faq-button');
        if (faqButton) {
          faqButton.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }, 100);
    }
  }

  toggleItem(index: number) {
    this.faqItems[index].isOpen = !this.faqItems[index].isOpen;
  }
}
