<div class="faq-container">
  <button
    class="faq-button"
    (click)="toggleFAQ()"
    [@slideToggle]="showFAQ ? 'expanded' : 'collapsed'">
    FAQ
  </button>
  @if (showFAQ) {
    <div class="my-accordion" [@fadeInOut]="showFAQ ? 'in' : 'out'">
      @for (item of faqItems; track $index) {
        <div class="my-accordion-item">
          <div class="my-accordion-header" (click)="toggleItem($index)">
            <div [innerHTML]="item.title | translate"></div>
            <i class="arrow" [ngClass]="item.isOpen ? 'up' : 'down'"></i>
          </div>
          @if (item.isOpen) {
            <div class="my-accordion-content">
              @for (data of item.text.split('\n'); track $index) {
                <div [innerHTML]="data | translate"></div>
              }
            </div>
          }
        </div>
      }
    </div>
  }
</div>
